import chroma from 'chroma-js';

interface GlobalFlags {
  poiId: string;
  linkColor: string;
  nodeErrorColor: string;
  poiColor: string;

  highlightSize: number;
  nodeHighlightColor: string;

  // ABFR
  aggregateBufferFillingRate: number[];
  aggregateBufferFillingRateColor: string[];

  // BufferHealth
  bufferHealth: number[];
  bufferHealthColor: string[];

  nodeSize: number;
  poiNodeSize: number;
  poiLevel: number;
  linkWidth: number;

  chargeForce: number;

  collideLevelWeight: number;
  collideRadius: number;

  linkForce: number;
  linkDistance: number;
  linkDistanceLevelWeight: number;

  velocityDecay: number;
  zoomToFitDelayMs: number;
  cooldownTimeMs: number;

  maxNumberTreeLevels: number;
  nodeRelativeSize: number;

  minScaling: number;
  maxScaling: number;
  viewAltitude: number;

  maxNodesNumber: number;
}

// https://www.w3.org/TR/css-color-3/
export const globalFlags: GlobalFlags = {
  poiId: '0',
  linkColor: chroma('white').hex(),
  nodeErrorColor: chroma('white').hex(),
  poiColor: chroma('cornflowerblue').hex(),

  highlightSize: 7,
  nodeHighlightColor: chroma('orange').hex(),

  // ABFR
  aggregateBufferFillingRate: [150, 300],
  aggregateBufferFillingRateColor: [chroma('darkred').hex(), chroma('lightgreen').hex(), chroma('teal').hex()],

  // BufferHealth [s]
  bufferHealth: [5, 10, 15],
  bufferHealthColor: [chroma('darkred').hex(), chroma('lightgreen').hex(), chroma('teal').hex()],

  nodeSize: 2,
  poiNodeSize: 15,
  poiLevel: 1,
  linkWidth: 2,

  chargeForce: -10,

  collideLevelWeight: 2.05,
  collideRadius: 80,

  linkForce: 10,
  linkDistance: 30,
  linkDistanceLevelWeight: 1.5,

  velocityDecay: 0.15,
  zoomToFitDelayMs: 200,
  cooldownTimeMs: 3000,

  maxNumberTreeLevels: 30,
  nodeRelativeSize: 1,

  minScaling: 0.01,
  maxScaling: 0.2,
  viewAltitude: 1,

  maxNodesNumber: 5000,
};
