import { useEffect, useState } from 'react';
import { PanelData } from '@grafana/data';
import { globalFlags } from 'globalflags';
import { MapCenterInfo, QueryFields, GraphDataNodes } from './../data/fields';
import {
  getReferenceUniqueIndexes,
  getCleanedFields,
  getCleanedField,
  mapCenterCalc,
  setGraphNodesLinks,
  setArcData,
  getMinMaxFromNumberField,
} from './../nodeutils';
import { format, differenceInCalendarDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

const changeToTimeZone = (timestamp: number, timezone: string) => {
  const timestampDate = new Date(timestamp);
  if (timezone !== 'browser') {
    const timezoneTranform = timezone.toLowerCase() === 'utc' ? timezone.toUpperCase() : timezone;
    return formatInTimeZone(timestampDate, timezoneTranform, 'yyyy-MM-dd HH:mm:ss');
  }

  return format(timestampDate, 'yyyy-MM-dd HH:mm:ss');
};

const useGraphData = (initialData: PanelData, uniqueFieldName: string) => {
  const initialMapCenterInfo: MapCenterInfo = { lat: 0, lng: 0, altitude: 0 };
  const initialGraphData: GraphDataNodes = { nodes: [] };
  const [graphData, setGraphData] = useState<GraphDataNodes>(initialGraphData);
  const [mapCenterInfo, setMapCenterInfo] = useState<MapCenterInfo>(initialMapCenterInfo);
  const [arcGraphData, setArcGraphData] = useState<any[]>([]);
  const [monthTimeRange, setMonthTimeRange] = useState<boolean>(false);
  const [graphDateFromInfo, setGraphDateFromInfo] = useState<string>('');
  const [graphDateToInfo, setGraphDateToInfo] = useState<string>('');
  const [graphDataLimited, setGraphDataLimited] = useState<boolean>(false);
  const [minBH, setMinBH] = useState<number>(0);
  const [maxBH, setMaxBH] = useState<number>(0);

  useEffect(() => {
    const processGraphData = () => {
      // Process graph data
      const dataFields = initialData?.series[0]?.fields || [];
      const cleanDataMask = getReferenceUniqueIndexes(dataFields, uniqueFieldName);
      const cleanedFields: QueryFields = getCleanedFields(dataFields, cleanDataMask);

      const latitudeMapCenter = mapCenterCalc(cleanedFields.latitudeValues);
      const longitudeMapCenter = mapCenterCalc(cleanedFields.longuitudeValues);
      const mapCenter = { lat: latitudeMapCenter, lng: longitudeMapCenter, altitude: globalFlags.viewAltitude };
      setMapCenterInfo(mapCenter);

      const nodes = setGraphNodesLinks(cleanedFields, latitudeMapCenter, longitudeMapCenter);
      const arcData = setArcData(nodes, cleanedFields, latitudeMapCenter, longitudeMapCenter);
      setArcGraphData(arcData);

      setMinBH(getMinMaxFromNumberField(cleanedFields.bufferHealthValues, 'min'));
      setMaxBH(getMinMaxFromNumberField(cleanedFields.bufferHealthValues, 'max'));

      nodes.push({
        id: globalFlags.poiId,
        parent: globalFlags.poiId,
        lat: (latitudeMapCenter + 20).toString(),
        lng: longitudeMapCenter.toString(),
        parentlat: '0',
        parentlng: '0',
        bh: '0',
        session: globalFlags.poiId,
        size: globalFlags.poiNodeSize,
        level: globalFlags.poiLevel,
        color: globalFlags.poiColor,
      }); // Insert the POI

      setGraphData({ nodes });

      // catch last date from data to show it in the graph info box
      let graphDateFrom = '';
      let graphDateTo = '';
      let graphDateFromTimestamp = initialData?.timeRange?.from.unix() * 1000;
      const graphDateToTimestamp = initialData?.timeRange?.to.unix() * 1000;
      let nodesDataLimited = false;
      
      if(cleanDataMask.length >= globalFlags.maxNodesNumber) {
        let timestampRows: string[] | number[] = getCleanedField(dataFields, cleanDataMask, 'timestamp');

        if (timestampRows.length) {
          graphDateFromTimestamp = getMinMaxFromNumberField(timestampRows, 'min');
          nodesDataLimited = true;
        }
      }

      graphDateFrom = changeToTimeZone(graphDateFromTimestamp, initialData.request?.timezone ?? 'browser');
      graphDateTo = changeToTimeZone(graphDateToTimestamp, initialData.request?.timezone ?? 'browser');
      setGraphDateFromInfo(graphDateFrom);
      setGraphDateToInfo(graphDateTo);
      setGraphDataLimited(nodesDataLimited);
    };

    const calcTimeRange = () => {
      // calc if grafana filter range is either around last 30 days or not
      const timeRangeFrom = initialData?.timeRange?.from;
      const timeRangeTo = initialData?.timeRange?.to;
      let timeRangeWithin = false;

      if (typeof timeRangeFrom.toDate === 'function' && typeof timeRangeTo.toDate === 'function') {
        const diffTimeRangeFrom = differenceInCalendarDays(new Date(timeRangeFrom.toDate()), new Date());
        const diffTimeRangeTo = differenceInCalendarDays(new Date(timeRangeTo.toDate()), new Date());
        timeRangeWithin = diffTimeRangeFrom <= 0 && diffTimeRangeFrom >= -30 && diffTimeRangeTo >= 0;
      }

      setMonthTimeRange(timeRangeWithin);
    };

    if (initialData?.state === 'Done') {
      processGraphData();
      calcTimeRange();
    }
  }, [initialData.state, initialData.request, initialData.series, initialData.timeRange, uniqueFieldName]);

  return {
    graph: {
      graphData,
      graphDataLimited,
      mapCenterInfo,
      arcGraphData,
      minBH,
      maxBH,
    },
    graphDateInfo: {
      graphDateFromInfo,
      graphDateToInfo,
    },
    monthTimeRange,
  }
};

export default useGraphData;
