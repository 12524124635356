/*
  ---< Query Fields Definition >---
*/
export interface QueryFields {
  idValues: string[];
  sourceValues: string[];
  latitudeValues: string[];
  longuitudeValues: string[];
  bufferHealthValues: string[];
  sessionValues: string[];
  sourceLatitudeValues: string[];
  sourceLongitudeValues: string[];
};

export const queryFieldsStructure = [
  {
    nodeName: 'idValues',
    fieldName: 'TargetId',
  },
  {
    nodeName: 'sourceValues',
    fieldName: 'TargetSource',
  },
  {
    nodeName: 'latitudeValues',
    fieldName: 'Latitude',
  },
  {
    nodeName: 'longuitudeValues',
    fieldName: 'Longitude',
  },
  {
    nodeName: 'bufferHealthValues',
    fieldName: 'BufferHealth',
  },
  {
    nodeName: 'sessionValues',
    fieldName: 'SessionId',
  },
  {
    nodeName: 'sourceLatitudeValues',
    fieldName: 'TargetSourceLatitude',
  },
  {
    nodeName: 'sourceLongitudeValues',
    fieldName: 'TargetSourceLongitude',
  },
];

export interface MapCenterInfo {
  lat: number;
  lng: number;
  altitude: number;
};

export interface GraphDataNodes {
  nodes: any[];
}
