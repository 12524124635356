import React from 'react';
//====== custom plugin
import 'style.css';
//====

export const Loading: React.FC = () => {
    return (
        <div className="loading-container">
            <div className="loading-spinner"></div>
        </div>
    )
};
