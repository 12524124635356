import { globalFlags } from './globalflags';
import { QueryFields, queryFieldsStructure } from './data/fields';

type minMax = 'min' | 'max';

export interface PolynetNodeObject {
  id?: string | number;
  x?: number;
  y?: number;
  z?: number;
  vx?: number;
  vy?: number;
  vz?: number;
  fx?: number;
  fy?: number;
  fz?: number;
}
// Extension of the interface PolynetNodeObject with metrics
export interface PolynetNodeObject3D extends PolynetNodeObject {
  lat: number;
  lng: number;
  parent: string;
  bh: number;
  session: string;
  size: number;
  level: number;
  color: string;
}

export function getNodeLevel(nodeId: string, reverseLinkMap: Map<string, string>) {
  let currentLevel = globalFlags.poiLevel;
  const stack: string[] = [nodeId];

  while (stack.length > 0) {
    const currentNodeId = stack.pop() as string;
    const parent = reverseLinkMap.get(currentNodeId);

    if (!parent || parent === globalFlags.poiId) {
      return currentLevel;
    }

    currentLevel++;
    if (currentLevel > globalFlags.maxNumberTreeLevels) {
      break;
    }

    stack.push(parent);
  }

  return currentLevel;
}

// Split huge arrays into chunks
export function chunckArray(arr: any[], size: number) {
  return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));
}

// Extract data field by name from Grafana data structure
export function getFieldDataByName({ json, fieldName }: { json: any; fieldName: string }) {
  return json.reduce((newValuesArr: any, currentField: any) => {
    if (currentField.name === fieldName) {
      let currentFieldValues;
      if (!Array.isArray(currentField.values)) {
        const objToArr: any[] = Object.values(currentField.values);
        currentFieldValues = objToArr.length && objToArr[0].length === currentField.values.length ? objToArr[0] : [];
      } else {
        currentFieldValues = currentField.values;
      }
      currentFieldValues = chunckArray(currentFieldValues, 50000);
      currentFieldValues.forEach((valuesChunk) => {
        newValuesArr.push(...valuesChunk);
      });
    }
    return newValuesArr;
  }, []);
}

export function getReferenceUniqueIndexes(data: any[], fieldReference: string): any[] {
  const fieldReferenceValues = getFieldDataByName({ json: data, fieldName: fieldReference });
  const fieldReferenceUniqueIndexes = Object.values(
    fieldReferenceValues.reduce(
      (indexesArr: any, refValue: any, refIndex: number) => ((indexesArr[refValue] ??= refIndex), indexesArr),
      {}
    )
  ).sort((a: any, b: any) => a - b);

  return fieldReferenceUniqueIndexes;
}

// generate clean fields object for advance and lite tier
export function getCleanedFields(data: any[], uniqueIndexes: any[]) {
  let cleanedFields: QueryFields = {
    idValues: [],
    sourceValues: [],
    latitudeValues: [],
    longuitudeValues: [],
    bufferHealthValues: [],
    sessionValues: [],
    sourceLatitudeValues: [],
    sourceLongitudeValues: [],
  };
  const tierDataStructure = queryFieldsStructure;

  if (uniqueIndexes.length) {
    tierDataStructure.forEach((tierField) => {
      const tierFieldData = getFieldDataByName({ json: data, fieldName: tierField?.fieldName });
      cleanedFields[tierField?.nodeName as keyof typeof cleanedFields] = uniqueIndexes.map(
        (refIndex: any) => tierFieldData[refIndex]
      );
    });
  }

  return cleanedFields;
}

export function getCleanedField(data: any[], uniqueIndexes: any[], fieldGetData: string) {
  const fieldGetValues = getFieldDataByName({ json: data, fieldName: fieldGetData });

  return uniqueIndexes.map((refIndex: any) => fieldGetValues[refIndex]);
}

export function mapCenterCalc(itemValues: string[]) {
  return itemValues.reduce((a: any, b: any) => a + b, 0) / itemValues.length;
}

export function setGraphNodesLinks(cleanedFields: QueryFields, latitudeMapCenter: number, longitudeMapCenter: number) {
  const dataLength = cleanedFields.idValues.length;
  const cdnLat = latitudeMapCenter + 20;
  const cdnLng = longitudeMapCenter;

  const links = Array.from({ length: dataLength }, (_, i) => ({
    source: cleanedFields.sourceValues[i],
    target: cleanedFields.idValues[i],
  }));

  const reverseLinkMap = new Map(links.map(({ source, target }) => [target, source]));

  const nodes = Array.from({ length: dataLength }, (_, i) => ({
    id: cleanedFields.idValues[i],
    parent: cleanedFields.sourceValues[i],
    lat: cleanedFields.latitudeValues[i],
    lng: cleanedFields.longuitudeValues[i],
    parentlat: cleanedFields.sourceValues[i] === 'CDN' ? cdnLat : cleanedFields.sourceLatitudeValues[i],
    parentlng: cleanedFields.sourceValues[i] === 'CDN' ? cdnLng : cleanedFields.sourceLongitudeValues[i],
    bh: cleanedFields.bufferHealthValues[i],
    session: cleanedFields.sessionValues[i],
    size: globalFlags.nodeSize,
    level: getNodeLevel(cleanedFields.idValues[i], reverseLinkMap),
    color: globalFlags.bufferHealthColor[1],
  }));

  return nodes;
}

export function setArcData(
  nodes: any[],
  cleanedFields: QueryFields,
  latitudeMapCenter: number,
  longitudeMapCenter: number
) {
  const dataLength = cleanedFields.idValues.length;

  // Map parent - geoCoord
  const nodeMap = new Map(
    nodes.map(({ id, lat, lng, parent, parentlat, parentlng }) => [id, { lat, lng, parent, parentlat, parentlng }])
  );

  // Build Arcs
  const arcData = Array.from({ length: dataLength }, (_, i) => ({
    endLat: nodeMap.get(cleanedFields.idValues[i])!.lat,
    endLng: nodeMap.get(cleanedFields.idValues[i])!.lng,
    startLat: nodeMap.get(cleanedFields.idValues[i])?.parentlat || latitudeMapCenter + 20,
    startLng: nodeMap.get(cleanedFields.idValues[i])?.parentlng || longitudeMapCenter + 20,
  }));

  return arcData;
}

// MIN MAX SCALING
export function scaleValue(value: number, minVal: number, maxVal: number, A: number, B: number): number {
  // scale the value
  const scaledValue = ((value - minVal) / (maxVal - minVal)) * (B - A) + A;

  return scaledValue;
}

export function getMinMaxFromNumberField(arr: any[], type: minMax) {
  const splitArr = chunckArray(arr, 50000);
  let reducedArr: any[] = [];
  splitArr.forEach((valuesChunk) => {
    const getMinMax = type === 'max' ? Math.max(...valuesChunk.map(Number)) : Math.min(...valuesChunk.map(Number));
    reducedArr.push(getMinMax);
  });

  return type === 'max' ? Math.max(...reducedArr.map(Number)) : Math.min(...reducedArr.map(Number));
}
