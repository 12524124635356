import React from 'react';
import { InfoBoxProps, InfoBoxItem } from 'types';

import { Tooltip } from 'react-tooltip';

//====== custom plugin
import 'style.css';
//====

const InfoBoxInfo: React.FC<InfoBoxItem> = ({label, value, tooltip, showLabel}) => {
    return (
        <li
        data-tooltip-id="my-tooltip"
         data-tooltip-content="content"
         data-tooltip-place="bottom"
        >
            {showLabel && <label>{label}</label>}
            <div className='content-value'>{value}</div>
            <Tooltip id="my-tooltip" className='tooltip-span' render={() => (
                <span>
                    {tooltip}
                </span>
            )}/>
        </li>
    )
}

export const InfoBox: React.FC<InfoBoxProps> = ({ items }) => {
    return <>
       {items.length &&  
        
        <div className="info-box-panel-globe">
          
            <ul className="info-box-content">
                {items.map(({label, value, tooltip, showLabel}, index) => {return <InfoBoxInfo key={index} label={label} value={value} tooltip={tooltip} showLabel={showLabel} />; })}
            </ul>

        </div>
       
       } 
    </>
};
